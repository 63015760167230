<template>
  <UDropdown
    mode="click"
    :items="items"
    :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' }, truncate: 'truncate' }"
    :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full"
  >
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="w-full"
        truncate
        :ui="{ truncate: 'truncate line-clamp-0' }"
        :label="auth.fullName"
        :class="[open && 'bg-gray-50 dark:bg-gray-800']"
        data-test="user-profile-dropdown"
      >
        <template #leading>
          <Avatar :id="auth.id" :src="auth.photo" :name="auth.fullName" size="2xs" />
        </template>

        <template #trailing>
          <UIcon name="i-heroicons-ellipsis-vertical" class="w-5 h-5 ml-auto" />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left w-full">
        <p class="truncate w-full font-medium text-gray-900 dark:text-white">
          {{ auth.fullName }}
        </p>
        <p class="truncate w-full">{{ auth.email }}</p>
      </div>
    </template>
  </UDropdown>
  <KeyboardShortcutsBoard />
</template>

<script setup lang="ts">
import { profileBaseUrl } from '#auth/utils'

const { auth } = storeToRefs(useAuthStore())
const { currentWorkspace } = useWorkspaceSharedState()

const items = computed(() => [
  [
    {
      slot: 'account',
      label: '',
      disabled: true,
    },
  ],
  [
    {
      label: 'Profile',
      icon: 'i-heroicons-user-circle',
      to: profileBaseUrl(currentWorkspace.value.handle) + '/settings',
    },
    {
      label: 'Keyboard shortcuts',
      icon: 'i-heroicons-keyboard',
      click: () => {
        const { emit } = useEventBus('toggle-shortcut-key-board')
        emit()
      }
    }
  ],
  [
    {
      label: 'Sign out',
      icon: 'i-heroicons-arrow-left-on-rectangle',
      to: '/logout',
    },
  ],
])
</script>
